var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"scrollable":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"width":_vm.$vuetify.breakpoint.md ? '60%' : _vm.$vuetify.breakpoint.lg ? '35%'
      : _vm.$vuetify.breakpoint.sm ? '60%' : _vm.$vuetify.breakpoint.xl  ? '35%' : '100%',"persistent":""},model:{value:(_vm.synced_is_open),callback:function ($$v) {_vm.synced_is_open=$$v},expression:"synced_is_open"}},[_c('v-card',{staticClass:"pt-5",attrs:{"rounded":"lg"}},[_c('v-row',{staticClass:"px-md-15 px-5 mt-16 mb-16",attrs:{"no-gutters":"","justify":"center"}},[_c('div',{staticClass:"d-flex flex-column mt-4 mb-4 text-center"},[_c('v-btn',{attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":_vm.edit_uploaded_image_model.closeEditUploadedImageModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-center primary--text mt-7 mb-9"},[_vm._v(" "+_vm._s(_vm.edit_uploaded_image_model.translate('edit_image'))+" ")])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('input',{staticClass:"d-none",attrs:{"type":"file","id":"upload_icon_button","accept":_vm.edit_uploaded_image_model.accepted_formats},on:{"change":_vm.edit_uploaded_image_model.onSelectedFile,"click":_vm.edit_uploaded_image_model.setFocusOnFileInput}}),_c('Cropper',{ref:"cropper",attrs:{"stencil-component":"circle-stencil","id":"cropper_component","src":_vm.edit_uploaded_image_model.selected_file,"stencil-props":{
              handlers: {},
              movable: false,
              resizable: false,
              aspectRatio: 1,
            },"stencil-size":{
              width: 150,
              height: 150
            },"resize-image":{
              adjustStencil: false
            },"image-restriction":"stencil"}})],1),_c('v-row',{staticClass:"mt-7"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-body mt-4",attrs:{"color":"secondary","x-large":"","rounded":"","block":""},on:{"click":_vm.edit_uploaded_image_model.closeEditUploadedImageModal}},[_vm._v(" "+_vm._s(_vm.edit_uploaded_image_model.translate('cancel'))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-body mt-4",attrs:{"color":"accent","x-large":"","rounded":"","block":""},on:{"click":_vm.edit_uploaded_image_model.cropImage}},[_vm._v(" "+_vm._s(_vm.edit_uploaded_image_model.translate('save'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }