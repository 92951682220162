



















































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import EditUploadedImageModalViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-goals/edit-uploaded-image-modal-view-model';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

@Component({
  name: 'EditUploadedImageModal',
  components: {
    Cropper,
    CircleStencil,
  },
})
export default class EditUploadedImageModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  edit_uploaded_image_model = Vue.observable(new EditUploadedImageModalViewModel(this));

  mounted() {
    this.edit_uploaded_image_model.initialize();
  }
}
