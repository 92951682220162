import TYPES from '@/types';
import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class EditUploadedImageModalViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  readonly i18n_namespace = 'components.goals-dashboard.edit-uploaded-image-modal';

  accepted_formats = 'image/png, image/jpeg';

  selected_file: string | ArrayBuffer | null = '';

  custom_base64_cropped_image = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = () => {
    this.inputFileClick();
  }

  inputFileClick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    document.getElementById('upload_icon_button').click();
  }

  onSelectedFile = ({ target }: any) => {
    const file = target.files[0];
    if (file.type.includes('png') || file.type.includes('jpeg')) {
      const oFReader = new FileReader();
      oFReader.onload = (oFREvent) => {
        this.selected_file = oFREvent.target!.result;
      };
      oFReader.readAsDataURL(file);
    } else {
      this.message_notifier.showErrorNotification(this.translate('errors.upload_icon_format'));
      this.closeEditUploadedImageModal();
    }
  }

  cropImage = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const { canvas } = this.view.$refs.cropper.getResult();
    this.custom_base64_cropped_image = canvas.toDataURL();
    this.view.$emit('setBase64CustomImage', this.custom_base64_cropped_image);
    this.closeEditUploadedImageModal();
  }

  setFocusOnFileInput = () => {
    document.body.onfocus = this.checkIfUserChoseAFile;
  }

  checkIfUserChoseAFile = () => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const file: HTMLElement = document.getElementById('upload_icon_button');
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (!file?.value.length) {
        this.closeEditUploadedImageModal();
      }
      document.body.onfocus = null;
    }, 300);
  }

  closeEditUploadedImageModal = () => {
    this.view.$emit('closeEditUploadedImageModal');
  }
}
